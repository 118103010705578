import { useQuery } from '@apollo/react-hooks'
import React, { useMemo } from 'react'
import { format, parseISO } from 'date-fns'
import { es } from 'date-fns/locale'
import query from '../../../graphql/queries/usuario'
import iconoExportar from '@iconify/icons-mdi/file-export-outline'
import iconoRefresh from '@iconify/icons-mdi/refresh'
import { InlineIcon } from '@iconify/react'
import { exportarEjercicios } from '../utilities'
import './Historial.css'

const Historial = ({ id }) => {

  const { data, loading, error, refetch } = useQuery(query, { variables: { id }, pollInterval: 5000 })

  const datos = useMemo(() => {
    if (loading) {
      return null
    }
    return data.user.ejercicios.slice()
      .map(d => ({
        ...d,
        fecha: format(parseISO(d.comentario.startsWith('FECHA') ? d.comentario.split('FECHA: ')[1] : d.createdAt), 'dd MMM yyyy', { locale: es }),
        hora: format(parseISO(d.comentario.startsWith('FECHA') ? d.comentario.split('FECHA: ')[1] : d.createdAt), 'HH:mm', { locale: es }),
        fechaISO: format(parseISO(d.comentario.startsWith('FECHA') ? d.comentario.split('FECHA: ')[1] : d.createdAt), 'yyyy MM dd HH:mm')
      }))
      .sort((f1, f2) => f1.fechaISO > f2.fechaISO ? -1 : 1)
  }, [loading, data])

  if (loading) {
    return 'cargando...'
  }

  if (error) {
    return 'error cargando los datos'
  }

  return (
    <div className="Historial">
      <div className="Historial__superior">
        <h1 className="Historial__titulo">Historial de ejercicios</h1>
        <div className="Historial__botones">
          <button
            className="Historial__boton"
            onClick={(e) => exportarEjercicios(e, datos)}
          >
            <InlineIcon className="Historial__icono" icon={iconoExportar} />
            Exportar
          </button>
          <button
            className="Historial__boton"
            onClick={() => refetch()}
          >
            <InlineIcon className="Historial__icono" icon={iconoRefresh} />
            Refrescar
          </button>
        </div>
      </div>
      <div className="Historial__contenedor_tabla">
        <table className="Historial__tabla">
          <thead>
            <tr className="Historial__tabla_encabezados">
              <th>Fecha</th>
              <th>Tipo</th>
              <th>Modo de juego</th>
              <th>Lugar cuerpo</th>
              <th className="Historial__tabla_celda_centrada">Tiempo</th>
              <th className="Historial__tabla_celda_centrada">Duración/<br/>Descanso</th>
              <th className="Historial__tabla_celda_centrada" title="Periodicidad">Period.</th>
              <th className="Historial__tabla_celda_centrada" title="Velocidad">Vel.</th>
              <th className="Historial__tabla_celda_centrada" title="Gravedad">Grav.</th>
              <th className="Historial__tabla_celda_centrada" title="Tamaño">Tam.</th>
              <th className="Historial__tabla_celda_centrada" title="Obstáculos esquivados">Esq.</th>
              <th className="Historial__tabla_celda_centrada" title="Obstáculos colisionados">Col.</th>
              <th className="Historial__tabla_celda_centrada">Puntaje</th>
              <th className="Historial__tabla_celda_centrada">Comentario</th>
            </tr>
          </thead>
          <tbody>
            {datos.map(({
              createdAt, tipo, modo, lugar_cuerpo, velocidad, tiempo,
              gravedad, tamano, periodicidad, esquivados, colisionados, 
              puntos, comentario, duracion_obstaculo, obstacle_rest,
              fecha, hora
            }, i) =>{
              let duracion_descanso = "(n.d.)"
              if (modo === 'Fuerza/Resistencia' && duracion_obstaculo) {
                duracion_descanso = `${duracion_obstaculo}[s]/${obstacle_rest}x `
              }
              return (
              <tr key={`fila-${i}`} className="Historial__tabla_fila">
                <td>
                  <p style={{ whiteSpace: 'nowrap' }}>{fecha}</p>
                  <p style={{ whiteSpace: 'nowrap' }}>a las {hora}</p>
                </td>
                <td>{tipo}</td>
                <td>{modo}</td>
                <td>{lugar_cuerpo}</td>
                <td className="Historial__tabla_celda_centrada">{tiempo}</td>
                <td className="Historial__tabla_celda_centrada">{duracion_descanso}</td>
                <td className="Historial__tabla_celda_centrada">{periodicidad}</td>
                <td className="Historial__tabla_celda_centrada">{velocidad}</td>
                <td className="Historial__tabla_celda_centrada">{Number(gravedad.split('x')[0]).toFixed(2)}x</td>
                <td className="Historial__tabla_celda_centrada">{tamano}</td>
                <td className="Historial__tabla_celda_centrada">{esquivados}</td>
                <td className="Historial__tabla_celda_centrada">{colisionados}</td>
                <td className="Historial__tabla_celda_centrada">{puntos}</td>
                <td className="Historial__tabla_celda_centrada" style={{ maxWidth: '5rem' }}>{comentario.startsWith('FECHA') ? '' : comentario}</td>
              </tr>
            )})}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Historial
