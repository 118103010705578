import React from 'react'
import { InlineIcon } from '@iconify/react'
import checkbox from '@iconify/icons-mdi/check-box-outline-blank'
import checkboxChecked from '@iconify/icons-mdi/check-box'
import classNames from 'classnames'
import './SelectorTratante.css'
import { useSelector } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import queryUsuario from '../../../../graphql/queries/usuario'
import agregarMonitoreoMutation from '../../../../graphql/mutations/agregarMonitoreo'
import borrarMonitoreoMutation from '../../../../graphql/mutations/borrarMonitoreo'

const SelectorTratante = ({ tratante, monitoreo }) => {
  
  const { id } = useSelector(state => state.jwt)
  const [agregarMonitoreo, { loading: agregandoMonitoreo }] = useMutation(agregarMonitoreoMutation)
  const [borrarMonitoreo, { loading: borrandoMonitoreo }] = useMutation(borrarMonitoreoMutation)

  const check = () => {
    if (agregandoMonitoreo || borrandoMonitoreo) {
      return
    }
    agregarMonitoreo({
      variables: {
        paciente: id,
        tratante: tratante.id
      },
      refetchQueries: [{ query: queryUsuario, variables: { id } }]
    })
  }

  const borrar = () => {
    if (agregandoMonitoreo || borrandoMonitoreo) {
      return
    }
    borrarMonitoreo({
      variables: { id: monitoreo.id },
      refetchQueries: [{ query: queryUsuario, variables: { id } }]
    })
  }

  return (
    <div
      key={id}
      className={classNames({
        SelectorTratante: true,
        'SelectorTratante--activo': monitoreo
      })}
      onClick={() => monitoreo ? borrar(monitoreo.id) : check(id)}
    >
      {agregandoMonitoreo || borrandoMonitoreo
        ? <div className={classNames({
            "SelectorTratante__cargando" : true,
            "SelectorTratante__cargando--blanco": borrandoMonitoreo
          })}/>
        : <InlineIcon
            className="SelectorTratante__checkbox"
            icon={monitoreo ? checkboxChecked : checkbox}
          />
      }
      <div className="SelectorTratante__identificacion">
        <div className="SelectorTratante__nombre">{tratante.nombre}</div>
        <div className="SelectorTratante__email">{tratante.email}</div>
      </div>
    </div> 
  )
}

export default SelectorTratante
