import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import editarMutation from '../../../graphql/mutations/editarUsuario'
import { useForm } from 'react-hook-form'
import query from '../../../graphql/queries/usuario'
import { useQuery } from '@apollo/react-hooks'
import './EditarCuenta.css'
import { useSelector } from 'react-redux'

const EditarCuenta = () => {

  const { register, handleSubmit, formState } = useForm()
  const [editar] = useMutation(editarMutation)
  const { id } = useSelector(state => state.jwt)
  const { data, loading } = useQuery(query, { variables: { id } })

  const enviarFormulario = variables => {
    editar({
      variables: { id, ...variables },
      refetchQueries: [{ query, variables: { id } }]
    })
  }

  if (loading) {
    return 'cargando...'
  }

  return (
    <div className="EditarCuenta">
      <div className="EditarCuenta__superior">
        <h2 className="EditarCuenta__titulo">Editar cuenta</h2>
      </div>
      <div className="EditarCuenta__contenedor">
        <form
          className="EditarCuenta__formulario"
          id="form_editar_usuario"
          onSubmit={handleSubmit(enviarFormulario)}
        >
          <h2 className="EditarCuenta__formulario_titulo">
            Editar información personal
          </h2>
          <label className="EditarCuenta__campo">
            <div className="EditarCuenta__label">Nombre</div> 
            <input
              className="EditarCuenta__input"
              name="nombre"
              defaultValue={data.user.nombre}
              ref={register}
            />
          </label>
          <label className="EditarCuenta__campo">
            <div className="EditarCuenta__label">Diagnóstico</div> 
            <input
              className="EditarCuenta__input"
              name="diagnostico"
              defaultValue={data.user.diagnostico}
              ref={register}
            />
          </label>
          <label className="EditarCuenta__campo">
            <div className="EditarCuenta__label">Teléfono</div> 
            <input
              className="EditarCuenta__input"
              name="telefono"
              defaultValue={data.user.telefono}
              ref={register}
            />
          </label>
          <label className="EditarCuenta__campo">
            <div className="EditarCuenta__label">Dirección</div> 
            <input
              className="EditarCuenta__input"
              name="direccion"
              defaultValue={data.user.direccion}
              ref={register}
            />
          </label>
          <button
            className="EditarCuenta__boton"
            disabled={Object.keys(formState.dirtyFields).length === 0}
            onClick={handleSubmit(enviarFormulario)}
          >
            Guardar cambios
          </button>
        </form>
      </div>
    </div>
  )
}

export default EditarCuenta
