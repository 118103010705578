import React, { useMemo, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useSelector } from 'react-redux'
import query from '../../../graphql/queries/usuario'
import './ListaPacientes.css'
import { useSortBy, useTable } from 'react-table'
import { parseISO, formatDistanceToNowStrict } from 'date-fns'
import { es } from 'date-fns/locale'
import { useHistory } from 'react-router-dom'
import { InlineIcon } from '@iconify/react'
import RegistroPaciente from '../../RegistroPaciente'
import iconoNuevoPaciente from '@iconify/icons-mdi/user-add'

const formatearFecha = f =>  formatDistanceToNowStrict(parseISO(f), { locale: es })

const ListaPacientes = () => {

  const { id } = useSelector(state => state.jwt)
  const { data, loading } = useQuery(query, { variables: { id } })
  const history = useHistory()
  const [agregando, setAgregando] = useState(false)

  const filas = useMemo(() => {
    const pacientes = data ? data.user.monitoreos_tratante.map(m => m.paciente) : []
    return pacientes.map(({ id, nombre, fecha_nacimiento, genero, email, telefono, ejercicios }) => ({
      id,
      nombre,
      fecha_nacimiento: formatearFecha(fecha_nacimiento),
      genero,
      email,
      telefono,
      fecha: ejercicios[0] ? formatearFecha(ejercicios[0].createdAt) : '-',
      fechaOriginal: ejercicios[0] ? ejercicios[0].createdAt : '-1'
    }))
  }, [data])

  const columnas = useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Nombre',
      accessor: 'nombre',
    },
    {
      Header: 'Edad',
      accessor: 'fecha_nacimiento',
    },
    {
      Header: 'Género',
      accessor: 'genero',
    },
    {
      Header: 'E-mail',
      accessor: 'email',
    },
    {
      Header: 'Teléfono',
      accessor: 'telefono',
    },
    {
      Header: 'Último ejercicio',
      accessor: 'fecha',
      sortType: (a, b) => a.fechaOriginal > b.fechaOriginal ? -1 : 1
    },
    {
      Header: 'Fecha original',
      accessor: 'fechaOriginal',
    },
  ], [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns: columnas, data: filas, initialState: { hiddenColumns: ['id', 'fechaOriginal'] } }, useSortBy)

  if (loading) {
    return 'cargando...'
  }

  const irAPerfilPaciente = idPaciente => () => {
    history.push(`/paciente/${idPaciente}`)
  }

  return (
    <div className="ListaPacientes">
      <div className="ListaPacientes__superior">
        <h1 className="ListaPacientes__titulo">Mis Pacientes</h1>
        <button
          className="ListaPacientes__boton_agregar_paciente"
          onClick={() => setAgregando(true)}
        >
          <InlineIcon className="ListaPacientes__icono_agregar_paciente" icon={iconoNuevoPaciente} />  Agregar paciente
        </button>
      </div>
      {agregando
        ? <RegistroPaciente idTratante={id} setAgregando={setAgregando} />
        : <table className="ListaPacientes__tabla" {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr className="ListaPacientes__encabezados" {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row)
                return (
                  <tr onClick={irAPerfilPaciente(row.values.id)} className="ListaPacientes__fila" {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
      }
    </div>
  )
}

export default ListaPacientes
