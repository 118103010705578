import React from 'react'
import './Estadisticas.css'
import { useQuery } from '@apollo/react-hooks'
import GraficoPorcentajeObstaculosEsquivados from './GraficoPorcentajeObstaculosEsquivados'
import query from '../../../graphql/queries/usuario'
import GraficoPorcentajeObstaculosColisionados from './GraficoPorcentajeObstaculosColisionados'
import GraficoCantidadObstaculosEsquivados from './GraficoCantidadObstaculosEsquivados'
import GraficoCantidadObstaculosColisionados from './GraficoCantidadObstaculosColisionados'
import GraficoTiempoJugadoPorDia from './GraficoTiempoJugadoPorDia'
import GraficoJuegosPorDia from './GraficoJuegosPorDia'

const Estadisticas = ({ id }) => {

  const { data, loading } = useQuery(query, { variables: { id } })

  if (loading) {
    return 'cargando...'
  }
  if (data.user.ejercicios.length === 0) {
    return (
    <div className="Estadisticas">
      <div className="Estadisticas__titulo">Estadísticas de ejercicios</div>
      <div className="Estadisticas__mensaje">
        <div className="Estadisticas__mensaje_label"><h2>No hay ejercicios realizados</h2></div> 
      </div>
    </div>)
  }

  const colores = {
    principal: '#0185a7',
    secundario: '#80D0C7',
    terciario: '#A62E12',
    cuaternario: '#0A35A6',
    quintario: '#A67C12',
    sectario: '#0AA66F',
  }

  return (
    <div className="Estadisticas">
      <div className="Estadisticas__titulo">Estadísticas de ejercicios</div>
      <div className="Estadisticas__contenedor">
        <div className="Estadisticas__contenedor_grafico">
          <GraficoPorcentajeObstaculosEsquivados
            ejercicios={data.user.ejercicios}
            color={colores.principal}
          /> 
        </div>
        <div className="Estadisticas__contenedor_grafico">
          <GraficoPorcentajeObstaculosColisionados
            ejercicios={data.user.ejercicios}
            color={colores.terciario}
          />
        </div>
        <div className="Estadisticas__contenedor_grafico">
          <GraficoTiempoJugadoPorDia
            ejercicios={data.user.ejercicios}
            color={colores.cuaternario}
          />
        </div>
        <div className="Estadisticas__contenedor_grafico">
          <GraficoCantidadObstaculosEsquivados
            ejercicios={data.user.ejercicios}
            color={colores.quintario}
          />
        </div>
        <div className="Estadisticas__contenedor_grafico">
          <GraficoCantidadObstaculosColisionados
            ejercicios={data.user.ejercicios}
            color={colores.secundario}
          />
        </div>
        <div className="Estadisticas__contenedor_grafico">
          <GraficoJuegosPorDia
            ejercicios={data.user.ejercicios}
            color={colores.sectario}
          />
        </div>
      </div>
    </div>
  )
}

export default Estadisticas
