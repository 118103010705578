import React, { useState, useMemo } from 'react'
import './MenuUsuario.css'
import query from '../../../../graphql/queries/usuario'
import { useQuery } from '@apollo/react-hooks'
import { useSelector } from 'react-redux'
import { InlineIcon } from '@iconify/react'
import chevronDown from '@iconify/icons-mdi/chevron-down'
import PopupMenuUsuario from './PopupMenuUsuario'

const calcularNivel = puntaje => {
  const nivel = Math.log(1 + (puntaje / 650.0)) / Math.log(1.04)
  if (nivel <= 1) return 1
  if (nivel >= 50) return 50
  return nivel
}

const MenuUsuario = () => {
  
  const [menuVisible, setMenuVisible] = useState(false)
  const { id, rol } = useSelector(state => state.jwt)
  const { data, loading, error } = useQuery(query, { variables: { id } })
  
  let puntajeTotal = useMemo(() => {
    if (loading) {
      return null
    }
    return data.user.score
    }, [loading, data])
    
  if (loading || error) {
    return null
  }

  if (!puntajeTotal) {
    puntajeTotal = 0
  }
  const nivel = Math.floor(calcularNivel(puntajeTotal))
  
  return (
    <div className="MenuUsuario">
      <div
        className="MenuUsuario__nombre" 
        onClick={() => setMenuVisible(!menuVisible)}
      >
        <div className="MenuUsuario__avatar">
          {data.user.nombre[0]}
        </div>
        <div className="MenuUsuario__identificacion">
          <div className="MenuUsuario__nombre">{data.user.nombre}</div>
          <div className="MenuUsuario__rol">{rol} - Nivel {nivel}</div>
        </div>
        <InlineIcon
          className="MenuUsuario__boton_menu"
          icon={chevronDown}
        />
      </div>
      <PopupMenuUsuario
        activo={menuVisible}
        cerrar={() => setMenuVisible(false)}
      />
    </div>
  )
}

export default MenuUsuario
