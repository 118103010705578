import gql from 'graphql-tag'

export default gql`
  mutation (
    $id: ID!
  ) {
    deleteMonitoreo (
      input: {
        where: {
          id: $id
        }
      }
    ) {
      monitoreo {
        id
      }
    }
  }
`