import React from 'react'
import './BarraSuperior.css'
import MenuUsuario from './MenuUsuario'

const BarraSuperior = () => {
  return (
    <div className="BarraSuperior">
      <MenuUsuario />
    </div>
  )
}

export default BarraSuperior
