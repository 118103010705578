import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import loginMutation from '../../graphql/mutations/login'
import { useDispatch } from 'react-redux'
import { tomaEsteToken } from '../../redux/ducks/jwt'
import './Login.css'
import { useHistory } from 'react-router-dom'
import logo from '../../assets/logo.png'
import classNames from 'classnames'

const mensajeSpam = 'Hemos enviado las instrucciones de recuperación a su correo. Asegúrse de revisar también la carpeta de SPAM.'

const Login = () => {

  const [variables, setVariables] = useState({
    usuario: '',
    password: ''
  })
  const [mutate, { loading }] = useMutation(loginMutation)
  const dispatch = useDispatch()
  const [error, setError] = useState(undefined)
  const history = useHistory()
  const [yendoARegistrar, setYendoARegistrar] = useState(false)

  const login = e => {
    e.preventDefault()
    mutate({ variables })
      .then(({ data }) => {
        dispatch(tomaEsteToken(data.login))
      })
      .catch(() => setError('Usuario o contraseña incorrectos. Estamos en proceso de migración. Si aún no lo ha hecho, por favor recupere su contraseña.'))
  }

  const irARegistrar = e => {
    e.preventDefault()
    setYendoARegistrar(true)
    setTimeout(() => history.push('/registro'), 750)
  }

  const irARecuperar = e => {
    e.preventDefault()
    setYendoARegistrar(true)
    setTimeout(() => history.push('/recuperacion'), 750)
  }
  
  return (
    <div className="Login">
      <form
        className={classNames({
          "Login__formulario": true,
          "Login__formulario--saliendo": yendoARegistrar
        })}
        onSubmit={login}
      >
        <div className="Login__contenedor_logo">
          <img src={logo} alt="logo emg one" className="Login__logo" />
          <h1 className="Login__nombre_app">EMG ONE</h1>
        </div>
        <div className="Login__campo">
          <label
            className="Login__label"
            htmlFor="usuario-login"
          >
            Usuario
          </label>
          <input
            className="Login__input"
            id="usuario-login"
            type="text"
            onChange={e => setVariables({ ...variables, usuario: e.target.value })}
            autoFocus
            required
          />
        </div>
        <div className="Login__campo">
          <label
            className="Login__label"
            htmlFor="password-login"
          >
            Contraseña
          </label>
          <input
            className="Login__input"
            id="password-login"
            type="password"
            onChange={e => setVariables({ ...variables, password: e.target.value })}
            required
          />
        </div>
        <button
          className="Login__boton"
          type="submit"
          disabled={loading}
        >
          Ingresar
        </button>
        {window.location.href.indexOf('recuperacion') > 0 && <p className="Login__error">{mensajeSpam}</p>}
        <p className="Login__error">{error}</p>
        <div className="Login__links">
          <button
            className={classNames({
              "Login__link": true,
              "Login__link--destacado": error
            })}
            onClick={irARecuperar}
          >
          Recuperar contraseña
          </button>
          <button className="Login__link" onClick={irARegistrar}>
            Registrar
          </button>  
          {/* <Link className="Login__link"to="/registro">Registrar</Link> */}
        </div>
      </form>
      {/* <a
        className="Login__manual"
        href={pdf}
        target="_blank"
        rel="noreferrer noopener"
      >
        <InlineIcon icon={iconoDescarga} /> Manual de Usuario
      </a> */}
    </div>
  )
}

export default Login
