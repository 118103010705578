import gql from 'graphql-tag'

export default gql`
  mutation (
    $id: ID!
    $nombre: String
    $diagnostico: String
    $telefono: String
    $direccion: String
  ) {
    updateUser (
      input: {
        where: {
          id: $id 
        }
        data: {
          nombre: $nombre
          diagnostico: $diagnostico
          telefono: $telefono
          direccion: $direccion
        }
      }
    ) {
      user {
        id
        nombre
        email
        diagnostico
        telefono
        direccion
      }
    }
  }
`