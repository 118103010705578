import React, { useState } from 'react'
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import './Exportar.css'
import es from 'date-fns/locale/es'
import { useQuery } from '@apollo/react-hooks'
import query from '../../../graphql/queries/usuario'
import { addDays, parseISO, startOfDay } from 'date-fns'
import { exportarEjercicios } from '../utilities'

const Exportar = ({ id }) => {
  registerLocale('es', es)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const { data } = useQuery(query, { variables: { id } })
  const ejercicios = data?.user.ejercicios.filter(e => parseISO(e.createdAt) >= startOfDay(startDate) && parseISO(e.createdAt) < addDays(endDate, 1))

  return (
    <div className="Exportar">
      <h1 className="Exportar__titulo">Exportar datos</h1>
      <div className="Exportar__contenedor">
        <form onSubmit={e => exportarEjercicios(e, ejercicios)} className="Exportar__formulario">
          <label className="Exportar__campo">
            <div className="Exportar__label">Fecha inicial</div> 
            <DatePicker
              selected={startDate}
              onChange={d => setStartDate(d)}
              maxDate={endDate}
              locale="es"
              dateFormat="dd MMMM yyyy"
            />
          </label>
          <label className="Exportar__campo">
            <div className="Exportar__label">Fecha final</div> 
            <DatePicker
              selected={endDate}
              onChange={d => setEndDate(d)}
              maxDate={Date.now()}
              locale="es"
              dateFormat="dd MMMM yyyy"
            />
          </label>
          <button
            className="Exportar__boton"
            type="submit"
          >
            Descargar
          </button>
        </form>
      </div>
    </div>
  )
}

export default Exportar
