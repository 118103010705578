import React from 'react'
import pdf from '../../../assets/Manual EMG One.pdf'
import iconoDescarga from '@iconify/icons-mdi/file-document'
import { InlineIcon } from '@iconify/react'
import './Inicio.css'

const Inicio = () => {
  return (
    <div className="Inicio">
      <a
        className="Inicio__manual"
        href={pdf}
        target="_blank"
        rel="noreferrer noopener"
      >
        <InlineIcon icon={iconoDescarga} /> Descargar Manual de Usuario
      </a>
      <iframe className="Inicio__video" title="Bienvenida EMG One" src="https://www.youtube.com/embed/EGgW4Kf1XMo" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  )
}

export default Inicio
