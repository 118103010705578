import gql from 'graphql-tag'

export default gql`
  mutation (
    $paciente: ID!
    $tratante: ID!
  ) {
    createMonitoreo (
      input: {
        data: {
          paciente: $paciente
          tratante: $tratante
        }
      }
    ) {
      monitoreo {
        id
      }
    }
  }
`