import React, { useState, useCallback } from 'react'
import queryTratantes from '../../../graphql/queries/tratantes'
import queryUsuario from '../../../graphql/queries/usuario'
import { useQuery } from '@apollo/react-hooks'
import { useSelector } from 'react-redux'
import SelectorTratante from './SelectorTratante'
import './ListaTratantes.css'
import Icon from '@iconify/react'
import iconoBusqueda from '@iconify/icons-mdi/search'
import iconoLimpiarBusqueda from '@iconify/icons-mdi/close'

const ListaTratantes = () => {

  const { id } = useSelector(state => state.jwt)
  const [busqueda, setBusqueda] = useState('')
  const { data: dataTratantes, loading: cargandoTratantes } = useQuery(queryTratantes)
  const { data: dataUsuario } = useQuery(queryUsuario, { variables: { id } })

  const filtrarTratantes = useCallback(u => {
    const busquedaNormalizada = busqueda.toLocaleLowerCase()
    const nombreNormalizado = u.nombre.toLocaleLowerCase()
    const emailNormalizado = u.email.toLocaleLowerCase()
    return nombreNormalizado.indexOf(busquedaNormalizada) >= 0 ||
      emailNormalizado.indexOf(busquedaNormalizada) >= 0
  }, [busqueda])

  if (cargandoTratantes) {
    return <div className="ListaTratantes__cargando" /> 
  }

  const { monitoreos_paciente: monitoreos } = dataUsuario.user

  return (
    <div className="ListaTratantes">
      <div className="ListaTratantes__superior">
        <h2 className="ListaTratantes__titulo">Autorizar tratantes</h2>
        <div className="ListaTratantes__contenedor_busqueda">
          <input
            type="text"
            className="ListaTratantes__buscador"
            placeholder="Buscar tratante"
            value={busqueda}
            onChange={e => setBusqueda(e.target.value)}
          />
          <Icon
            className="ListaTratantes__icono_busqueda"
            icon={busqueda ? iconoLimpiarBusqueda : iconoBusqueda}
            onClick={() => setBusqueda('')}
          />
        </div>
      </div>
      <p className="ListaTratantes__explicacion">
        Autorizo a los siguientes profesionales para monitorear mis ejercicios:
      </p>
      <div className="ListaTratantes__lista">
        {dataTratantes.users.filter(filtrarTratantes).map(tratante => {
          const monitoreo = monitoreos.find(m => m.tratante.id === tratante.id)
          return (
            <SelectorTratante
              key={`selector-tratante-${tratante.id}`}
              tratante={tratante}
              monitoreo={monitoreo}
            />
        )})}
      </div>
    </div>
  )
}

export default ListaTratantes
