import React, { useState, useRef, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import { useForm } from "react-hook-form";
import registrarPaciente from '../../graphql/mutations/registrarPaciente'
import agregarMonitoreo from '../../graphql/mutations/agregarMonitoreo'
import queryUsuario from '../../graphql/queries/usuario'
import TOS from './TOS'
import './RegistroPaciente.css'
import classNames from 'classnames'

const RegistroPaciente = ({ idTratante, setAgregando }) => {

  const { register, handleSubmit, watch, errors } = useForm()
  const nombreRef = useRef()
  const [errorGraphQL, setErrorGraphQL] = useState()
  const [tosOK, setTosOK] = useState(false)
  const [mostrarTOS, setMostrarTOS] = useState(false)

  const [registrar, { loading }] = useMutation(registrarPaciente)
  const [monitorear, { loading: loadingMonitoreo }] = useMutation(agregarMonitoreo)
  const history = useHistory()

  useEffect(() => nombreRef.current.focus(), [])

  const enviarFormulario = async variables => {
    try {
      const { data } = await registrar({ variables })
      if (idTratante) {
        const { id } = data.createUser.user
        await monitorear({
          variables: { paciente: id, tratante: idTratante },
          refetchQueries: [{
            query: queryUsuario,
            variables: { id: idTratante }
          }]
        })
        setAgregando(false)
      }
      else {
        history.push('/')
      }
    } catch (e) {
      if (e.graphQLErrors) {
        setErrorGraphQL('E-mail ya estaba registrado')
      }
    }
  }

  return (
    <div className={classNames({
      "RegistroPaciente": true,
      "RegistroPaciente--incrustado": idTratante !== undefined
    })}>
      {mostrarTOS && <TOS cerrar={() => setMostrarTOS(false)} />}
      <form
        onSubmit={handleSubmit(enviarFormulario)}
        className="RegistroPaciente__formulario"
      >
        <h1 className="RegistroPaciente__titulo">Registro en EMG ONE</h1>
        <div
          className="RegistroPaciente__campo"
          campo="nombre"
        >
          <label 
            htmlFor="registro-nombre"
            className="RegistroPaciente__label"
          >
            Nombre
          </label>
          <input
            id="registro-nombre"
            className="RegistroPaciente__input"
            name="nombre"
            ref={ref => {
              nombreRef.current = ref
              register(ref, { required: true, minLength: 3 })
            }}
          />
          {errors.nombre && <div className="RegistroPaciente__error">Este campo es requerido</div>}
        </div>
        <div
          className="RegistroPaciente__campo"
          campo="fechaNacimiento"
        >
          <label 
            className="RegistroPaciente__label"
            htmlFor="fechaNacimiento"
          >
            Fecha de nacimiento
          </label>
          <input
            id="fechaNacimiento"
            type="date"
            className="RegistroPaciente__input"
            name="fechaNacimiento"
            ref={register({ required: true, minLength: 3 })}
          />
          {errors.fechaNacimiento && <div className="RegistroPaciente__error">Este campo es requerido</div>}
        </div>
        <div
          className="RegistroPaciente__campo"
          campo="genero"
        >
          <label 
            className="RegistroPaciente__label"
            htmlFor="genero"
          >
            Género
          </label>
          <select
            id="genero"
            className="RegistroPaciente__input"
            name="genero"
            ref={register({ required: true, minLength: 3 })}
          >
            <option value="femenino">Femenino</option>
            <option value="masculino">Masculino</option>
            <option value="otro">Otro</option>
          </select>
          {errors.genero && <div className="RegistroPaciente__error">Este campo es requerido</div>}
        </div>
        <div className="RegistroPaciente__campo">
          <label
            htmlFor="registro__diagnostico"
            className="RegistroPaciente__label"
          >
            Diagnóstico
          </label>
          <input
            id="registro__diagnostico"
            className="RegistroPaciente__input"
            name="diagnostico"
            ref={register}
          />
        </div>
        <div className="RegistroPaciente__campo">
          <label
            htmlFor="registro-telefono"
            className="RegistroPaciente__label"
          >
            Teléfono
          </label>
          <input
            id="registro-telefono"
            className="RegistroPaciente__input"
            name="telefono"
            ref={register}
            autoComplete="tel"
          />
        </div>
        <div className="RegistroPaciente__campo">
          <label
            htmlFor="registro-direccion"
            className="RegistroPaciente__label"
          >
            Dirección
          </label>
          <input
            id="registro-direccion"
            className="RegistroPaciente__input"
            name="direccion"
            ref={register}
            autoComplete="address"
          />
        </div>
        <div className="RegistroPaciente__campo">
          <label
            htmlFor="registro-email"
            className="RegistroPaciente__label"
          >
            Email
          </label>
          <input 
            id="registro-email"
            className="RegistroPaciente__input"
            type="email"
            name="email"
            ref={register({ required: true })}
            autoComplete="off"
          />
          {errors.email && <div className="RegistroPaciente__error">Este campo es requerido</div>}
        </div>
        <div
          className="RegistroPaciente__campo"
          campo="password"
        >
          <label
            htmlFor="registro-password"
            className="RegistroPaciente__label"
          >
            Contraseña
          </label>
          <input
            id="registro-password"
            className="RegistroPaciente__input"
            type="password"
            name="password"
            ref={register({ required: true })}
          />
          {errors.password && <div className="RegistroPaciente__error">Este campo es requerido</div>}
        </div>
        <div
          className="RegistroPaciente__campo"
          campo="confirmacion-password"
        >
          <label
            htmlFor="registro-password2"
            className="RegistroPaciente__label"
          >
            Confirmar contraseña
          </label>
          <input
            id="registro-password2"
            className="RegistroPaciente__input"
            type="password"
            name="passwordConf"
            ref={register({ validate: v => v === watch('password') })}
          />
          {errors.passwordConf && <div className="RegistroPaciente__error">Contraseñas no coinciden</div>}
        </div>
        <div className="RegistroPaciente__inferior">
          <div className="RegistroPaciente__tos">
            <input type="checkbox" onChange={e => setTosOK(e.target.checked)} />
            Al registrarme acepto los <span className="RegistroPaciente__link_tos" onClick={() => setMostrarTOS(true)}>términos y condiciones de EMG ONE</span>
          </div>
          <button 
            type="submit"
            className="RegistroPaciente__boton"
            disabled={!tosOK || loading || loadingMonitoreo}
          >
            Registrar
          </button>
        </div>
        {errorGraphQL && <div className="RegistroPaciente__error">{errorGraphQL} - Recuperar contraseña</div>}
      </form>
    </div>
  )
}

export default RegistroPaciente
