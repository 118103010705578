import React from 'react'
import { useForm } from 'react-hook-form'
import './Recuperacion.css'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'

const Recuperacion = () => {

  const { register, handleSubmit } = useForm()
  const [cargando, setCargando] = useState(false)
  const history = useHistory()

  const enviarEmail = ({ email }) => {
    setCargando(true)
    axios
      .post('https://emgone.cl/forgot-password', { email })
      .then(() => history.push('/?recuperacion'))
      .catch(error => console.log('An error occurred:', error.response))
  }

  return (
    <div className="Recuperacion">
      <form 
        onSubmit={handleSubmit(enviarEmail)}
        className="Recuperacion__formulario"
      >
        <h1 className="Recuperacion__titulo">Recuperación de contraseña</h1>
        <div
          className="Recuperacion__campo"
        >
          <label 
            className="Recuperacion__label"
            htmlFor="recuperacion-email"
          >Ingrese su e-mail</label>
          <input 
            id="recuperacion-email"
            className="RegistroPaciente__input"
            type="email" 
            name="email" 
            ref={register} 
          />
        </div>
        <div className="Recuperacion__contenedor_boton">
          <button 
            type="submit"
            className="Recuperacion__boton"
            disabled={cargando}
          >
            {cargando ? 'Enviando...' : 'Enviar e-mail'}
          </button>
        </div>
      </form>
    </div>
  )
}

export default Recuperacion
