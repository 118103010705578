import { useQuery } from '@apollo/react-hooks'
import React from 'react'
import { useParams } from 'react-router-dom'
import query from '../../../../graphql/queries/usuario'
import Estadisticas from '../../Estadisticas'
import Historial from '../../Historial'
import './PerfilPaciente.css'

const PerfilPaciente = () => {

  const { id } = useParams()
  const { data, loading } = useQuery(query, { variables: { id }})

  if (loading) {
    return 'cargando...'
  }

  return (
    <div className="PerfilPaciente">
      <h1 className="PerfilPaciente__titulo">Mis Pacientes &gt; { data.user.nombre }</h1>
      <Historial id={id} />
      <Estadisticas id={id} />
    </div>
  )
}

export default PerfilPaciente
