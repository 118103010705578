import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import BarraLateral from './BarraLateral'
import BarraSuperior from './BarraSuperior'
import EditarCuenta from './EditarCuenta'
import Estadisticas from './Estadisticas'
import Exportar from './Exportar'
import Historial from './Historial'
import Inicio from './Inicio'
import ListaPacientes from './ListaPacientes'
import PerfilPaciente from './ListaPacientes/PerfilPaciente'
import ListaTratantes from './ListaTratantes/ListaTratantes'
import './Plataforma.css'

const Plataforma = () => {

  const { jwt, id } = useSelector(state => state.jwt)
  const history = useHistory()

  if (!jwt) {
    history.push('/')
    return null
  }

  return (
    <div className="Plataforma">
      <BarraLateral />
      <div className="Plataforma__contenedor">
        <BarraSuperior />
        <div className="Plataforma__centro">
          <Switch>
            <Route path="/inicio" component={Inicio} />
            <Route path="/historial" component={() => <Historial id={id} />} />
            <Route path="/estadisticas" component={() => <Estadisticas id={id} />} />
            <Route path="/exportar" component={() => <Exportar id={id} />} />
            <Route path="/tratantes" component={ListaTratantes} />
            <Route path="/usuario" component={EditarCuenta} />
            <Route path="/pacientes" component={ListaPacientes} />
            <Route path="/paciente/:id" component={PerfilPaciente} />
            <Redirect exact from="/" to="inicio" />
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default Plataforma
