import { decode } from 'jsonwebtoken'

const guardarToken = 'jwt/guardarToken'
const limpiarToken = 'jwt/limpiarToken'
const guardarUsuario = 'jwt/guardarUsuario'

const isDev = false && window.location.href.indexOf('localhost') >= 0

const defaultState = {
  jwt: !isDev ? undefined : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmNmJhZmY4NzRjZjg2NTI3MTU5ZTI1ZiIsImlhdCI6MTYwNDk0NDg2MiwiZXhwIjoxNjA3NTM2ODYyfQ.rCqlnzNO5ajDbZwl5wYPposaeMXuRG3AmqIRjitF0ac',
  id: !isDev ? undefined : decode('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmNmJhZmY4NzRjZjg2NTI3MTU5ZTI1ZiIsImlhdCI6MTYwNDk0NDg2MiwiZXhwIjoxNjA3NTM2ODYyfQ.rCqlnzNO5ajDbZwl5wYPposaeMXuRG3AmqIRjitF0ac').id,
  rol: !isDev ? undefined : 'Tratante'
}

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case guardarToken: {
      return {
        ...state,
        jwt: action.payload.jwt,
        id: decode(action.payload.jwt).id,
        rol: action.payload.user.role.name
      }
    }
    case limpiarToken: {
      return {
        ...state,
        jwt: undefined
      }
    }
    case guardarUsuario: {
      return {
        ...state,
        usuario: action.payload
      }
    }
    default: {
      return state
    }
  }
}

export const tomaEsteToken = json => {
  return { type: guardarToken, payload: json }
}

export const cierraLaSesion = () => {
  return { type: limpiarToken }
}

export const guardaUsuario = usuario => {
  return { type: guardarUsuario, payload: usuario }
}