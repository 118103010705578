import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import './RestauracionContraseña.css'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

const RestauracionContraseña = () => {

  const { register, handleSubmit } = useForm()
  const [cargando, setCargando] = useState(false)
  const history = useHistory()

  const restaurarContraseña = ({ password, passwordConfirmation }) => {
    setCargando(true)
    let params = new URLSearchParams(document.location.search.substring(1));
    let code = params.get("code")
    axios
      .post('https://emgone.cl/reset-password', { password, passwordConfirmation, code })
      .then(() => history.push('/'))
      .catch(error => console.log('An error occurred:', error.response))
  }

  return (
    <div className="RestauracionContraseña">
      <form 
        onSubmit={handleSubmit(restaurarContraseña)}
        className="RestauracionContraseña__formulario"
      >
        <h1 className="RestauracionContraseña__titulo">Restauración de contraseña</h1>
        <div
          className="RestauracionContraseña__campo"
        >
          <label 
            className="RestauracionContraseña__label"
            htmlFor="RestauracionContraseña-password"
          >Ingrese nueva contraseña</label>
          <input 
            id="RestauracionContraseña-password"
            className="RegistroPaciente__input"
            type="password" 
            name="password" 
            ref={register} 
          />
        </div>
        <div
          className="RestauracionContraseña__campo"
        >
          <label 
            className="RestauracionContraseña__label"
            htmlFor="RestauracionContraseña-password-conf"
          >Confirme nueva contraseña</label>
          <input 
            id="RestauracionContraseña-password-conf"
            className="RegistroPaciente__input"
            type="password" 
            name="passwordConfirmation" 
            ref={register} 
          />
        </div>
        <div className="RestauracionContraseña__contenedor_boton">
          <button 
            type="submit"
            className="RestauracionContraseña__boton"
            disabled={cargando}
          >
            {cargando ? 'Restaurando...' : 'Restaurar'}
          </button>
        </div>
      </form>
    </div>
  )
}

export default RestauracionContraseña
