import React from 'react'
import { NavLink } from 'react-router-dom'
import './BarraLateral.css'
import logo from '../../../assets/logo.png'
import { InlineIcon } from '@iconify/react'
import iconoHistorial from '@iconify/icons-mdi/history'
import iconoEstadisticas from '@iconify/icons-mdi/chart-timeline-variant'
import iconoExportar from '@iconify/icons-mdi/file-export-outline'
import iconoInicio from '@iconify/icons-mdi/home'
import iconoPacientes from '@iconify/icons-mdi/users-check'
import { useSelector } from 'react-redux'
import { ROL_TRATANTE } from '../../../config/roles'

const BarraLateral = () => {

  const { rol } = useSelector(state => state.jwt)

  return (
    <div className="BarraLateral">
      <div className="BarraLateral__contenedor_logo">
        <img src={logo} alt="logo emg one" className="BarraLateral__logo" />
        <h1 className="BarraLateral__nombre_app">EMG ONE</h1>
      </div>
      <div className="BarraLateral__links">
        {rol === ROL_TRATANTE ? <LinksTratante /> : <LinksPaciente />}
      </div>
    </div>
  )
}

const LinksPaciente = () => (
  <>
    <NavLink
      to="/inicio"
      className="BarraLateral__link"
      activeClassName="BarraLateral__link--activo"
    >
      <InlineIcon className="BarraLateral__icono" icon={iconoInicio} />
      Inicio
    </NavLink>
    <NavLink
      to="/historial"
      className="BarraLateral__link"
      activeClassName="BarraLateral__link--activo"
    >
      <InlineIcon className="BarraLateral__icono" icon={iconoHistorial} />
      Historial de ejercicios
    </NavLink>
    <NavLink
      to="/estadisticas"
      className="BarraLateral__link"
      activeClassName="BarraLateral__link--activo"
    >
      <InlineIcon className="BarraLateral__icono" icon={iconoEstadisticas} />
      Estadísticas de ejercicios
    </NavLink> 
    <NavLink
      to="/exportar"
      className="BarraLateral__link"
      activeClassName="BarraLateral__link--activo"
    >
      <InlineIcon className="BarraLateral__icono" icon={iconoExportar} />
      Exportar datos
    </NavLink> 
  </>
)

const LinksTratante = () => (
  <>
    <NavLink
      to="/inicio"
      className="BarraLateral__link"
      activeClassName="BarraLateral__link--activo"
    >
      <InlineIcon className="BarraLateral__icono" icon={iconoInicio} />
      Inicio
    </NavLink>
    <NavLink
      to="/pacientes"
      className="BarraLateral__link"
      activeClassName="BarraLateral__link--activo"
    >
      <InlineIcon className="BarraLateral__icono" icon={iconoPacientes} />
      Mis Pacientes
    </NavLink>
    <NavLink
      to="/historial"
      className="BarraLateral__link"
      activeClassName="BarraLateral__link--activo"
    >
      <InlineIcon className="BarraLateral__icono" icon={iconoHistorial} />
      Historial de ejercicios
    </NavLink>
    <NavLink
      to="/estadisticas"
      className="BarraLateral__link"
      activeClassName="BarraLateral__link--activo"
    >
      <InlineIcon className="BarraLateral__icono" icon={iconoEstadisticas} />
      Estadísticas de ejercicios
    </NavLink> 
    <NavLink
      to="/exportar"
      className="BarraLateral__link"
      activeClassName="BarraLateral__link--activo"
    >
      <InlineIcon className="BarraLateral__icono" icon={iconoExportar} />
      Exportar datos
    </NavLink>
  </>
)

export default BarraLateral
