import gql from 'graphql-tag'

export default gql`
  query Usuario(
    $id: ID!
  ) {
    user(id: $id) {
      id
      nombre
      email
      fecha_nacimiento
      genero
      diagnostico
      telefono
      direccion
      score
      monitoreos_paciente {
        id
        tratante {
          id
        }
      }
      monitoreos_tratante {
        id
        paciente {
          id
          nombre
          email
          fecha_nacimiento
          genero
          telefono
          ejercicios(
            sort: "createdAt:desc"
            limit: 1
          ) {
            createdAt
          }
        }
      }
      ejercicios {
        createdAt
        tipo
        modo
        lugar_cuerpo
        velocidad
        tiempo
        gravedad
        tamano
        periodicidad
        duracion_obstaculo
        obstacle_rest
        esquivados
        colisionados
        puntaje
        puntos
        comentario
      }
    }
  }
`