import React from 'react'
import { Line } from 'react-chartjs-2'
import { format, parseISO } from 'date-fns'
import './GraficoJuegosPorDia.css'
import { es } from 'date-fns/locale'

const GraficoJuegosPorDia = ({ ejercicios, color }) => {

  const juegosPorDia = ejercicios.reduce((r, e) => {
    r[format(parseISO(e.createdAt), 'dd MMM yy', { locale: es })] = [...r[format(parseISO(e.createdAt), 'dd MMM yy', { locale: es })] || [], e.tiempo ]
    return r
  }, {})

  const labels = Object.keys(juegosPorDia).slice(-20)
  const data = Object.keys(juegosPorDia).map(fecha => juegosPorDia[fecha].length).slice(-20)
  const promedio = data.reduce((a, b) => (a + b)) / data.length

  return (
    <div className="GraficoJuegosPorDia">
      <div className="GraficoJuegosPorDia__titulo">
        Juegos por día
      </div>
      <div className="Estadisticas__promedio">
        <div className="Estadisticas__numero" style={{color}}>{promedio.toLocaleString('de-DE', { maximumFractionDigits: 1 })}</div>
        <div className="Estadisticas__descripcion" style={{color}}>promedio</div>
      </div>
      <Line
        data={{
          labels,
          datasets: [{
            data,
            borderColor: color,
            backgroundColor: `${color}55`
          }]
        }}
        options={{
          legend: {
            display: false
          },
          tooltips: {
            callbacks: {
              label: items => `${Number(items.value).toLocaleString('de-DE', { maximumFractionDigits: 1 })}%`
            }
          },
          scales: {
            yAxes: [{
              ticks: {
                min: 0,
                callback: item => `${item}`
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              }
            }]
          },
        }}
      />
    </div>
  )
}

export default GraficoJuegosPorDia
