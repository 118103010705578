import gql from 'graphql-tag'

export default gql`
  {
    users (
      where: {
        role : {
          name: "Tratante"
        }
      }
    ) {
      id
      nombre
      email
    }
  }
`