import React from 'react'
import { Line } from 'react-chartjs-2'
import { format, parseISO } from 'date-fns'
import './GraficoPorcentajeObstaculosEsquivados.css'
import { es } from 'date-fns/locale'

const GraficoPorcentajeObstaculosEsquivados = ({ ejercicios, color }) => {

  const labels = ejercicios.map(e => format(parseISO(e.createdAt), 'dd MMM yy', { locale: es })).slice(-20)
  const data = ejercicios.map(e => 100.0 * e.esquivados / (e.esquivados + e.colisionados)).slice(-20)
  const promedio = data.reduce((a, b) => (a + b)) / data.length;

  return (
    <div className="GraficoPorcentajeObstaculosEsquivados">
      <div className="GraficoPorcentajeObstaculosEsquivados__titulo">
        Porcentaje de obstáculos esquivados
      </div>
      <div className="Estadisticas__promedio">
        <div className="Estadisticas__numero" style={{color}}>{promedio.toLocaleString('de-DE', { maximumFractionDigits: 1 })}%</div>
        <div className="Estadisticas__descripcion" style={{color}}>promedio</div>
      </div>
      <Line
        data={{
          labels,
          datasets: [{
            data,
            borderColor: color,
            backgroundColor: `${color}55`
          }]
        }}
        options={{
          legend: {
            display: false
          },
          tooltips: {
            callbacks: {
              label: items => `${Number(items.value).toLocaleString('de-DE', { maximumFractionDigits: 1 })}%`
            }
          },
          scales: {
            yAxes: [{
              ticks: {
                min: 0,
                max: 100,
                callback: item => `${item}%`
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              }
            }]
          },
        }}
      />
    </div>
  )
}

export default GraficoPorcentajeObstaculosEsquivados
