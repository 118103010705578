export const exportarEjercicios = (e, ejercicios) => {
  e.preventDefault()
  const headers = [
    'createdAt', 'tipo', 'modo', 'lugar_cuerpo', 'velocidad', 'tiempo',
    'gravedad', 'tamano', 'periodicidad', 'duracion_descanso',
    'esquivados', 'colisionados', 'puntos', 'comentario']
  const headersCSV = headers.join(',')
  const ejerciciosCSV = ejercicios.map(e => headers.map(h => e[h]).join(',')).join('\n')
  const texto = `${headersCSV}\n${ejerciciosCSV}`
  const nombreArchivo = 'ejercicios.csv'
  const elemento = document.createElement('a')
  elemento.setAttribute('href', `data:text/plaincharset=utf-8,${encodeURIComponent(texto)}`)
  elemento.setAttribute('download', nombreArchivo)
  elemento.style.display = 'none'
  document.body.appendChild(elemento)
  elemento.click()
  document.body.removeChild(elemento)
}