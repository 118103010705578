import React from 'react'
import './TOS.css'

const TOS = ({ cerrar }) => {
  return (
    <div className="TOS">
      <div className="TOS__tarjeta">
        <div className="TOS__contenedor">
          <div className="TOS__titulo">
            Términos y condiciones de EMG ONE
          </div>
          <div className="TOS__texto">
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Praesentium sapiente minus omnis quibusdam facilis magni explicabo ex laboriosam.
              Omnis obcaecati neque fugit ipsum odio sed iure accusamus consequuntur eveniet quibusdam.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Praesentium sapiente minus omnis quibusdam facilis magni explicabo ex laboriosam.
              Omnis obcaecati neque fugit ipsum odio sed iure accusamus consequuntur eveniet quibusdam.
            </p>
          </div>
        </div>
        <button className="TOS__boton_cerrar" onClick={cerrar}>Aceptar</button>
      </div>
    </div>
  )
}

export default TOS
