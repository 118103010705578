import gql from 'graphql-tag'

export default gql`
  mutation(
    $email: String!
    $password: String!
    $nombre: String!
    $genero: String!
    $fechaNacimiento: Date!
    $diagnostico: String
    $telefono: String
    $direccion: String
  ) {
    createUser(
      input: {
        data: {
          username: $email
          email: $email
          password: $password
          nombre: $nombre
          fecha_nacimiento: $fechaNacimiento
          genero: $genero
          diagnostico: $diagnostico
          telefono: $telefono
          direccion: $direccion
          score: 0
        }
      }
    ) {
      user {
        id
      }
    }
  }
`