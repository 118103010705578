import React from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { cierraLaSesion } from '../../../../../redux/ducks/jwt'
import classNames from 'classnames'
import './PopupMenuUsuario.css'
import { ROL_TRATANTE } from '../../../../../config/roles'

const PopupMenuUsuario = ({ activo, cerrar }) => {

  const { rol } = useSelector(state => state.jwt)
  const dispatch = useDispatch()

  const cerrarSesion = () => {
    dispatch(cierraLaSesion())
    window.location.href = '/'
  }

  return ReactDOM.createPortal(
    <div className="PopupMenuUsuario__fondo" onClick={cerrar} style={{ pointerEvents: activo ? 'all' : 'none' }}>
      <div
        className={classNames({
          PopupMenuUsuario: true,
          'PopupMenuUsuario--activo': activo
        })}
      >
        {rol !== ROL_TRATANTE &&
          <NavLink
            className="PopupMenuUsuario_link"
            to="/tratantes"
            onClick={cerrar}
          >
            Autorizar tratantes
          </NavLink>
        }
        <NavLink
          className="PopupMenuUsuario_link"
          to="/usuario"
          onClick={cerrar}
        >
          Editar cuenta
        </NavLink>
        <NavLink
          className="PopupMenuUsuario_link"
          to="/"
          onClick={cerrarSesion}
        >
          Cerrar sesión
        </NavLink>
      </div>
    </div>
  , document.getElementById('popup'))
}

export default PopupMenuUsuario
