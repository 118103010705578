import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Login from '../Login'
import RegistroPaciente from '../RegistroPaciente'
import Plataforma from '../Plataforma'
import { useSelector } from 'react-redux'
import Recuperacion from '../Recuperacion'
import './App.css'
import RestauracionContraseña from '../RestauracionContraseña'

const App = () => {

  const { jwt } = useSelector(state => state.jwt)

  if (jwt) {
    return <div className="App"><Plataforma /></div>
  }

  return (
    <div className="App">
      <Switch>
        <Route path="/registro" component={RegistroPaciente} />
        <Route path="/recuperacion" component={Recuperacion} />
        <Route path="/reset" component={RestauracionContraseña} />
        <Route path="/" component={Login} />
      </Switch>
    </div>
  )
}

export default App
